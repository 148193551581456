<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<div class="list-box">
					<div class="tit">
                        공급기업 지정 프로젝트 > 전체 프로젝트
                        <p class="sub_tit"><span>{{prjList.length}}</span>개의 프로젝트가 있습니다!</p>
                    </div>
					<div class="search-box interval">
						<SelectComp type="select" title="승인 상태" list="1:미승인,2:승인" :isAll="true" v-model="srchFilter.projectApprStatus" /><nbsp/>
                        <SelectComp type="select" title="프로젝트 상태" list="4:제안중,2:수행예정,1:수행중,9:완료" :isAll="true" v-model="srchFilter.projectStatus" /><nbsp/>
											
						<InputComp type="text" :placeholder="'프로젝트명'" v-model="srchFilter.searchKeyword" @keyup.enter="getDcoPgmPrjAppPrjList('1')" />
						<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getDcoPgmPrjAppPrjList('1')"/>
					</div>
					<div class="filter">
						<div class="filter_box"> 
							<ul>
								<li @click="changeOrderGubun('1')"><img src="/images/icon_filter.png" alt="등록일 순" :class="srchFilter.orderGubun == '1' ? 'filter' : ''"/><p>등록일 순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('2')"><img src="/images/icon_filter.png" alt="개발기간 긴 순" :class="srchFilter.orderGubun == '2' ? 'filter' : ''"/><p>개발기간 긴 순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('3')"><img src="/images/icon_filter.png" alt="개발기간 짧은 순" :class="srchFilter.orderGubun == '3' ? 'filter' : ''"/><p>개발기간 짧은 순</p><span>ㅣ</span></li>
								<li @click="changeOrderGubun('4')"><img src="/images/icon_filter.png" alt="지정기업 많은 순" :class="srchFilter.orderGubun == '4' ? 'filter' : ''"/><p>지정기업 많은 순</p><span>ㅣ</span></li>
                                <li @click="changeOrderGubun('5')"><img src="/images/icon_filter.png" alt="지정기업 적은 순" :class="srchFilter.orderGubun == '5' ? 'filter' : ''"/><p>지정기업 적은 순</p></li>
							</ul>
						</div>
                        <div class="prj-regist-btn" :class="this.$store.state.userInfo.mberDivCd == '21' ? 'pb-9' : ''">
                            <div v-if="mberDiv">
                                <button type="button" @click="$router.push('PRJ301M01')">프로젝트 등록</button>
                            </div>
                        </div>
					</div>

					<div class="Board type3">
						<table class="Board_type3 prj list-all" style="margin-left: 0;">
							<colgroup v-if="!mberDiv">
                                <col width="3%">
                                <col width="*">
                                <col width="15%">
                                <col width="8%">
                                <col width="8%">
                                <col width="4%">
                                <col width="4%">
                                <col width="11%">
								<col width="12%">
                            </colgroup>
							<colgroup v-else>
                                <col width="3%">
                                <col width="*">
                                <col width="16%">
                                <col width="8%">
                                <col width="8%">
                                <col width="4%">
                                <col width="5%">
                                <col width="12%">
                            </colgroup>
							<thead>
								<tr>
									<th>NO</th>
									<th>프로젝트명</th>
									<th>프로젝트 기간</th>
									<th>등록일</th>
									<th>프로젝트 상태</th>
                                    <th>지정<br/>기업</th>
                                    <th>등록<br/>기업</th>
									<th>공급기업별 프로젝트</th>
									<th v-if="!mberDiv">담당자</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(project, index) in prjList" :key="index">
									<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
									<td class="score prj-name">
                                        <div style="display: flex; justify-content: space-between; align-items: center; margin: 0 10px;">
                                            <div class="rc_tag" >
												<div v-if="project.apprYn != 'Y'" class="rc_mark_red">미승인</div>
                                                <!-- <div v-else-if="project.projectStatus == 4" class="rc_mark_black">제안단계</div> -->
                                            </div>
                                            <div class="project">{{project.projectNm}}</div>
                                            <div v-if="mberDiv" class="btn" style="min-width: 110px;" @click="goModifyProject(project)">프로젝트 관리 &#9654;</div>
                                        </div>
									</td>
									<td class="score">{{project.projectStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{project.projectEndYyyymmdd | date('yyyy.MM.dd')}}</td>
									<td class="score">{{project.regDate | date('yyyy.MM.dd')}}</td>
									<td class="score">                                        
										<span v-if="project.projectStatus == 1"><div class="state pf"></div>수 행 중</span>
										<span v-if="project.projectStatus == 4"><div class="state us"></div>제 안 중</span>
										<span v-if="project.projectStatus == 2"><div class="state ep"></div>수행예정</span>
										<span v-if="project.projectStatus == 9"><div class="state cp"></div>수행완료</span>
									</td>
                                    <td class="score">{{project.totCnt}}</td>
                                    <td class="score">{{project.prjCnt}}</td>
									<td class="score resume">
										<div class="btn" v-if="project.apprYn != 'N'" style="min-width: 110px;" @click="clickProjectDetail(project)">리스트 보기 &#9654;</div>
										<div v-else style="min-width: 110px;">-</div>
									</td>
									<td v-if="!mberDiv" class="score">
										<span class="btn_cursor" @click="mberPopup(project.corpMgrMberSeq)">{{project.corpMgrMberNm != null ? project.corpMgrMberNm + ' &#9654;' : ''}}</span>
										<div class="btn change" @click="clickChangeCorpMgrMberNm(project.parentProjectSeq)">변경 <div class="triangle"></div></div>
									</td>
								</tr>
								
								<!-- 등록된 프로젝트가 없을 경우 -->
								<tr v-if="prjList.length <= 0">
									<td colspan="7" class="none">프로젝트가 없습니다!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- 페이징부분 -->
					<pagingComp :pageInfo="pageInfo" @page-click="goPage" />
				</div>
			</div>
			<!-- footer -->
			<!-- <footer-layout type="left"/> -->
		</div>
	</div>	
</template>

<script>
// import gradeImg from "@/components/highpro/GradeImg.vue"
import pagingComp from '@/components/PagingComp.vue';
// import tecProfile from "@/components/highpro/TecProfile.vue";
// import footerLayout from "@/components/layout/footer.vue";
// import fixTop from "@/components/FixTop.vue";

export default {
	data() {
		return {
			pageInfo : {},
			corpdiv : this.$store.state.userInfo.mberDivCd,

			srchFilter : {
				searchKeyword : '',
				orderGubun : 1, 
				pageIndex : 1,
				searchGubun : 1,
			},

			
			prjList :[],

		
			nowYyyymmdd : new Date().format("yyyyMMdd"),
		}
	},

	components : {
		pagingComp
	},

	beforeMount() {
	
		this.getDcoPgmPrjAppPrjList();
	},

	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		getDcoPgmPrjAppPrjList(div) {
			var param = this.srchFilter;
			param.pageUnit = 20;
			param.pageSize = 10;
			param.corpdiv = this.corpdiv;
			if(div) this.srchFilter.pageIndex = 1;

			this.$.httpPost('/api/prj/sco/choice/getParentProjectList', param)
				.then((res) => {
					// console.log('getParentProjectList RESULT', res.data);
					this.prjList = res.data.prjList;
					this.pageInfo = res.data.pageInfo;
				});
		},

	

		// 정렬 순서
		changeOrderGubun(orderGubun) {
			this.srchFilter.orderGubun = orderGubun;
			this.srchFilter.pageIndex = 1;
			this.getDcoPgmPrjAppPrjList();
		},

		// 페이지 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getDcoPgmPrjAppPrjList();
		},

		// 프로젝트 관리
		goModifyProject(project) {
			if (this.$store.state.userInfo.mberDivCd == '22') {
				this.$router.push({name : 'PRJ303M02', query : {projectSeq : project.parentProjectSeq}, params : {caller : { name : this.$route.name, params :this.input}}});
			} else {
				this.$router.push({name : 'PRJ201M02', query : {projectSeq : project.parentProjectSeq}, params : {caller : { name : this.$route.name, params :this.input}}});
			}
			
		},

		// 자식프로젝트 등록 보기
		clickProjectDetail(project) {
			// this.$router.push({name : 'PRJ309M02', query : {projectSeq : project.parentProjectSeq}, params : { caller : { name : this.$route.name}}});
			if(project.prjCnt <= 0) {
				alert("등록된 프로젝트가 없습니다.");
			} else {
				if (this.$store.state.userInfo.mberDivCd == '22') {
					this.$router.push({name : 'PRJ309M02', query : {projectSeq : project.parentProjectSeq}, params : { caller : { name : this.$route.name}}});
				} else {
					this.$router.push({name : 'PRJ201M03', query : {projectSeq : project.parentProjectSeq}, params : { caller : { name : this.$route.name}}});
				}
			}
			
		},

		mberPopup(seq) {
			var div = 'dco';
			this.$.popup('/dco/gmg/myp/MYP201P01', { seq, div });
		},

		clickChangeCorpMgrMberNm(projectSeq) {
			var param = {
				projectSeq : projectSeq,
				parentProjectYn : 'Y',
			};
			this.$.popup('/dco/gmg/prj/PRJ201P01', param, {width:0, height:0})
				.then(() => {
					this.getDcoPgmPrjAppPrjList();
				})
				// .catch(err => {})
				;
		},

	},
	computed : {
		mberDiv() {
			// 수요 Y 관리총괄 N
			if(this.$store.state.userInfo.mberDivCd == '22') return true
			return false;
		}
	}
}
</script>